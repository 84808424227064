import { Button, Link } from '@mui/material';
import React from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import learning from './Images/learning-style.png';
import Students from './Images/math-students.png';
import Experienced from './Component/Experienced';

const theme = createTheme({
    palette: {
        primary: {
            main: "#B14D4E", // custom primary color
        },
        secondary: {
            main: "#C13E39", // custom secondary color
        },
    },
});

export default function Home() {
    return (
        <div className='container mx-auto'>

            <div className='px-10'>
                <div className='pt-5'><Experienced /></div>
                <div>


                    <div className='Edu text-2xl'>
                        If your child is struggling with math, please don’t wait to get them some help – each year builds on the next. Getting help earlier means that tutoring can be a short-term endeavor. And, most of all, your child’s confidence in math will not fall due to years of failure. Math is a subject that is best supported proactively – waiting simply makes it worse because the student falls further behind and starts to “hate math”.
                    </div>

                    <div className='clear-both lg:w-1/2 mx-auto'>
                        <div className='pt-5 pb-5'>
                            <ThemeProvider theme={theme}>
                                <Button variant='contained' color='primary' size='large' fullWidth><LocalPhoneIcon />&nbsp;Call Today</Button>
                            </ThemeProvider>
                        </div>
                    </div>

                    <div className='pt-5 Edu text-2xl'>
                        Some parents hire their student’s math teacher to tutor them after school. Here is what is wrong with that: there are many ways of approaching each math topic. Your child is receiving one method in school and it’s not working well for your child’s learning style.<br /><br />By hiring the math teacher to tutor, your son or daughter is simply receiving more of what is NOT working. That is not the best way to go.
                    </div>
                    <div className='lg:float-right lg:ml-10 lg:pt-5 mb-5'>
                        <img src={learning} alt='' title='' className='mx-auto rounded-xl shadow shadow-[#615454]' />
                    </div>
                    <div className='pt-5 Edu text-2xl'>
                        <div>
                            The other mistake is hiring a stellar high school or college math student to help. Truthfully, if they were that good then they have no idea how to communicate the concepts and methods to a child who doesn’t “get it” like they did.<br /><br />That is probably the worst thing you could do and could easily further undermine your child’s confidence. They may know math, but they don’t know teaching.
                        </div>
                    </div>

                    <div className='pt-5 Edu text-2xl'>
                        <div>
                            I use a more visual style of teaching – math lends itself readily to visual methods, but the schools often use verbal methods, which are limited in their effectiveness. They “explain” concepts and problems where I like to “show”. I also teach more holistically – simply teaching a student how to solve a particular type of problem is not going to help them for long. They need to understand the bigger picture of what they are doing, so they can apply this week’s lesson to next week’s lesson.
                        </div>
                    </div>

                </div>




                <div className='clear-both lg:w-1/2 mx-auto'>
                    <div className='pt-5 pb-5'>
                        <ThemeProvider theme={theme}>
                            <Button variant='contained' color='primary' size='large' fullWidth><Link href='/Contact' exact style={{ textDecoration: 'none', color: '#ffffcc' }}><ExitToAppIcon />&nbsp;Contact</Link></Button>
                        </ThemeProvider>


                    </div>
                </div>
            </div>

        </div>
    )
}
