
import { Divider } from '@mui/material';
import './App.css';
import Developed from './Component/Developed';
import Footer from './Component/Footer';
import Header from './Component/Header';
import { Link, BrowserRouter as Router, Route } from "react-router-dom";
import Home from './Home';
import { Switch } from "react-router-dom";
import Contact from './Contact';
import Navigation from './Component/Navigation';
import Testimonials from './Testimonials';
import Geometry from './Geometry';
import SummerTutoring from './SummerTutoring';
import Elementary from './Elementary';

function App() {
  return (
    <div className='bg-[#ffffcc] text-[#B14D4E] font-bold'>
      <div><Navigation /></div>
      <div><Header /></div>
      <div><Divider /></div>

      <Router>
        <Switch>

          <Route exact path="/" component={Home}></Route>
          <Route exact path="/home" component={Home}></Route>
          <Route exact path="/Geometry" component={Geometry}></Route>
          <Route exact path="/Elementary" component={Elementary}></Route>
          <Route exact path="/SummerTutoring" component={SummerTutoring}></Route>
          <Route exact path="/Testimonials" component={Testimonials}></Route>
          <Route exact path="/Contact" component={Contact}></Route>

        </Switch>






        <div className='container mx-auto'>


          <div className='px-10'>


            <div className='mt-5'><Footer /></div>
            <div className='pb-5'><Developed /></div>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
