import React, { useState } from 'react';
import '../App.css';
import { styled } from '@mui/material/styles';
import {
    Drawer,
    Box,

    Link,
    Divider,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { CompanyName } from './CompanyName';

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
    },
    "& .MuiDrawer-paper": {
        backgroundColor: "#B14D4E",
        //position: "relative",
        //overflow: "hidden",
        "&::before": {
            content: '""',
            // display: "block",
            //position: "absolute",
            top: 0,
            //left: 0,
            width: 240,
            backgroundColor: '#B14D4E',
            zIndex: -1,
        },
    },
}));

const Navigation = () => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div className='bg-[#B14D4E] print:hidden'>
            <div>

                <ul className='lg:flex lg:flex-row justify-end list-none p-0'>

                    <li className='px-3 lg:py-5 py-5 text-lg Borel'><Link href='/' style={{ textDecoration: 'none', color: '#ffffcc' }} exact>Home</Link></li>

                    <Divider variant="middle" className='bg-[#ffffcc]' />
                    <li className='px-3 lg:py-5 py-2 text-lg Borel'><Link href='/Elementary' style={{ textDecoration: 'none', color: '#ffffcc' }} exact> Elementary, Middle</Link></li>

                    <Divider variant="middle" className='bg-[#ffffcc]' />
                    <li className='px-3 lg:py-5 py-2 text-lg Borel'><Link href='/SummerTutoring' style={{ textDecoration: 'none', color: '#ffffcc' }} exact> Pre-algebra, algebra</Link></li>
                    <Divider variant="middle" className='bg-[#ffffcc]' />
                    <li className='px-3 lg:py-5 py-2 text-lg Borel'><Link href='/Geometry' style={{ textDecoration: 'none', color: '#ffffcc' }} exact> Geometry & Above</Link></li>

                    <Divider variant="middle" className='bg-[#ffffcc]' />
                    <li className='px-3 lg:py-5 py-2 text-lg Borel'><Link href='/Testimonials' style={{ textDecoration: 'none', color: '#ffffcc' }} exact> Testimonials</Link></li>

                    <Divider variant="middle" className='bg-[#ffffcc]' />

                    <li className='px-3 lg:py-5 py-2 text-lg Borel'><Link href='/Contact' style={{ textDecoration: 'none', color: '#ffffcc' }} exact> Contact</Link></li>
                </ul>
            </div>

        </div>
    );

    return (
        <>
            <Box className="print:hidden" Box sx={{ display: { md: 'none' } }
            }>
                <IconButton onClick={handleDrawerToggle}>
                    <MenuIcon />
                </IconButton>
            </Box >
            <StyledDrawer variant="temporary" anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
                {drawer}
            </StyledDrawer>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                {drawer}
            </Box>
        </ >
    );
};

export default Navigation;