import { Divider } from '@mui/material'
import React from 'react'
import CompanyName from './CompanyName';
import Link from '@mui/material/Link';

const today = new Date();

export default function Footer() {
    return (
        <div className='clear-both'>
            {/* <div>Company Name</div> */}
            <div><Divider /></div>
            <div className='pt-5'>
                Copyright <Link href='/Home' color={'inherit'} className='' exact="true">&copy; {today.getFullYear()} - <CompanyName /></Link> All rights reserved
            </div>
        </div>
    )
}
