import React from 'react'
import Experienced from './Component/Experienced';
import algebra from './Images/algebra.jpg';


export default function SummerTutoring() {
    return (
        <div className='container mx-auto'>
            <div className='px-10'>
                <div className='pt-5'><Experienced /></div>
                <div className='lg:float-right lg:ml-10 mb-5'>
                    <img src={algebra} alt='' title='' className='mx-auto rounded-xl shadow shadow-[#615454]' />
                </div>
                <div className=''>If your child is having trouble in middle school math, pre-algebra or algebra, please don’t delay in getting them the help they need. The problem can be, and should be, addressed now.</div>
                <div className='pt-5'>The classroom teachers must cover so much material during the year that they cannot take the extra time to explain lessons that the students find difficult. The state requires that they move on. Some teachers truly don’t care whether the students understand or not. Other teachers simply don’t teach. Did you know that the textbook publishers supply power-point lessons for most units? Some teachers simply play these and expect students to learn. No one learns math by watching a video! You learn it first and foremost by doing it.</div>
                <div className='pt-5'>I’ll make sure that they learn the math the teacher “covers”</div>
            </div>
        </div>
    )
}
