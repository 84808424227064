import React from 'react'
import Personalized from './Images/Personalized.png'
import { Divider } from '@mui/material'

export default function Geometry() {
    return (
        <div className='container mx-auto'>
            <div className='px-10'>
                <div className='lg:float-right mb-5'>
                    <div><img src={Personalized} alt='' title='' className='mx-auto' /></div>
                    <div><Divider /></div>
                </div>
                <div>
                    <div className='lg:pt-5'>
                        These are demanding high school courses. Many of the high school teachers, frankly, are uninspiring or simply don't teach well. They are mandated to move ahead, whether or not the class understands the concepts.
                    </div>
                    <div className='pt-5'>
                        However, these grades count. Moreover, your son or daughter needs these skills and concepts for the SAT and ACT college entrance exams. If they don't truly understand the mathematics, it will negatively affect their scores.
                    </div>



                    <div className='pt-5'>
                        Understanding these subjects thoroughly will not only improve their grades, their SAT or ACT scores will be significantly higher as well.
                    </div>

                </div>
            </div>
        </div>
    )
}
