import React from 'react'

import One from '../Images/1.png';
import Two from '../Images/2.png';
import Three from '../Images/3.png';
import Four from '../Images/4.png';
import Five from '../Images/5.png';
import Six from '../Images/6.png';
import Seven from '../Images/7.png';
import Eight from '../Images/8.png';
import Nine from '../Images/9.png';
import Ten from '../Images/10.png';
import { motion } from 'framer-motion';
import { Link } from '@mui/material';
import CompanyName from './CompanyName';
import Logo from '../Images/Logo.png'

export default function Header() {
    return (
        <div>

            <div className='flex mx-auto justify-center relative'>
                <div className='text-center absolute bottom-10 right-10  justify-center mx-auto  text-white z-20'>
                    <div><img src={Logo} alt='' title='' className='mx-auto ' /></div>
                    <div className='pt-3'><Link href='/' exact style={{ textDecoration: 'none', color: '#ffffcc' }}><h2 className='custom-font font-bold text-4xl Borel'><CompanyName /></h2></Link></div>

                </div>
                <div className='w-1/10'>

                    <div>
                        <motion.div
                            initial={{ opacity: 80, x: 100 }} // Initial opacity is 0 (completely transparent) and position is off-screen to the right
                            animate={{
                                opacity: 1, // Animation will change the opacity to 1 (fully opaque)
                                x: 0, // Animation will move the image to the center position (change this value to adjust the final position)

                            }}
                            transition={{
                                duration: 0.3, // Duration of the animation in seconds
                            }}
                        >
                            <img src={One} alt='' title='' className='mx-auto ' />
                        </motion.div>



                    </div>
                </div>
                <div className='w-1/10 '>
                    <div>
                        <motion.div
                            initial={{ opacity: 80, x: 100 }} // Initial opacity is 0 (completely transparent) and position is off-screen to the right
                            animate={{
                                opacity: 1, // Animation will change the opacity to 1 (fully opaque)
                                x: 0, // Animation will move the image to the center position (change this value to adjust the final position)

                            }}
                            transition={{
                                duration: 0.5, // Duration of the animation in seconds
                            }}
                        >
                            <img src={Two} alt='' title='' className='mx-auto ' />
                        </motion.div>
                    </div>


                </div>
                <div className='w-1/10'>

                    <div>
                        <motion.div
                            initial={{ opacity: 80, x: 100 }} // Initial opacity is 0 (completely transparent) and position is off-screen to the right
                            animate={{
                                opacity: 1, // Animation will change the opacity to 1 (fully opaque)
                                x: 0, // Animation will move the image to the center position (change this value to adjust the final position)

                            }}
                            transition={{
                                duration: 0.7, // Duration of the animation in seconds
                            }}
                        >
                            <img src={Three} alt='' title='' className='mx-auto ' />
                        </motion.div>
                    </div>
                </div>
                <div className='w-1/10'>

                    <div>
                        <motion.div
                            initial={{ opacity: 80, x: 100 }} // Initial opacity is 0 (completely transparent) and position is off-screen to the right
                            animate={{
                                opacity: 1, // Animation will change the opacity to 1 (fully opaque)
                                x: 0, // Animation will move the image to the center position (change this value to adjust the final position)

                            }}
                            transition={{
                                duration: 0.9, // Duration of the animation in seconds
                            }}
                        >
                            <img src={Four} alt='' title='' className='mx-auto ' />
                        </motion.div>
                    </div>
                </div>
                <div className='w-1/10'>

                    <div>
                        <motion.div
                            initial={{ opacity: 80, x: 100 }} // Initial opacity is 0 (completely transparent) and position is off-screen to the right
                            animate={{
                                opacity: 1, // Animation will change the opacity to 1 (fully opaque)
                                x: 0, // Animation will move the image to the center position (change this value to adjust the final position)

                            }}
                            transition={{
                                duration: 1.1, // Duration of the animation in seconds
                            }}
                        >
                            <img src={Five} alt='' title='' className='mx-auto ' />
                        </motion.div>
                    </div>

                </div>
                <div className='w-1/10'>

                    <div>
                        <motion.div
                            initial={{ opacity: 80, x: 100 }} // Initial opacity is 0 (completely transparent) and position is off-screen to the right
                            animate={{
                                opacity: 1, // Animation will change the opacity to 1 (fully opaque)
                                x: 0, // Animation will move the image to the center position (change this value to adjust the final position)

                            }}
                            transition={{
                                duration: 1.3, // Duration of the animation in seconds
                            }}
                        >
                            <img src={Six} alt='' title='' className='mx-auto ' />
                        </motion.div>
                    </div>
                </div>
                <div className='w-1/10'>

                    <div>
                        <motion.div
                            initial={{ opacity: 80, x: 100 }} // Initial opacity is 0 (completely transparent) and position is off-screen to the right
                            animate={{
                                opacity: 1, // Animation will change the opacity to 1 (fully opaque)
                                x: 0, // Animation will move the image to the center position (change this value to adjust the final position)

                            }}
                            transition={{
                                duration: 1.5, // Duration of the animation in seconds
                            }}
                        >
                            <img src={Seven} alt='' title='' className='mx-auto ' />
                        </motion.div>
                    </div>
                </div>
                <div className='w-1/10'>

                    <div>
                        <motion.div
                            initial={{ opacity: 80, x: 100 }} // Initial opacity is 0 (completely transparent) and position is off-screen to the right
                            animate={{
                                opacity: 1, // Animation will change the opacity to 1 (fully opaque)
                                x: 0, // Animation will move the image to the center position (change this value to adjust the final position)

                            }}
                            transition={{
                                duration: 1.7, // Duration of the animation in seconds
                            }}
                        >
                            <img src={Eight} alt='' title='' className='mx-auto ' />
                        </motion.div>
                    </div>
                </div>
                <div className='w-1/10'>

                    <div>
                        <motion.div
                            initial={{ opacity: 80, x: 100 }} // Initial opacity is 0 (completely transparent) and position is off-screen to the right
                            animate={{
                                opacity: 1, // Animation will change the opacity to 1 (fully opaque)
                                x: 0, // Animation will move the image to the center position (change this value to adjust the final position)

                            }}
                            transition={{
                                duration: 1.9, // Duration of the animation in seconds
                            }}
                        >
                            <img src={Nine} alt='' title='' className='mx-auto ' />
                        </motion.div>
                    </div>
                </div>
                <div className='w-1/10'>

                    <div>
                        <motion.div
                            initial={{ opacity: 80, x: 100 }} // Initial opacity is 0 (completely transparent) and position is off-screen to the right
                            animate={{
                                opacity: 1, // Animation will change the opacity to 1 (fully opaque)
                                x: 0, // Animation will move the image to the center position (change this value to adjust the final position)

                            }}
                            transition={{
                                duration: 2.1, // Duration of the animation in seconds
                            }}
                        >
                            <img src={Ten} alt='' title='' className='mx-auto ' />
                        </motion.div>
                    </div>
                </div>
            </div>





        </div >
    )
}
