import { Divider } from '@mui/material'
import React from 'react'

export default function Experienced() {
    return (
        <div>
            <div className='mb-5 text-center Edu text-2xl'>
                <div>Experienced and effective Math Tutor</div>
                <div>Hollywood, Hallandale beach, Aventura, n. Miami beach, Dania beach.</div>
                <div>Florida Certified Teacher Mathematics 5th – 12 Grades</div>
                <div>University of Pennsylvania Graduate</div>
            </div>
            <div className='mb-5'><Divider /></div>
        </div>
    )
}
