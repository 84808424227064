import React from 'react';
import Awesome from './Images/Awesome-Experience.png';
import Study from './Images/Study-math.png';
import Algebra from './Images/Algebra-kid.png';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from '@mui/system';




export default function Testimonials() {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Custom styles
    const TransparentAccordion = styled(Accordion)(({ theme }) => ({
        backgroundColor: '#B14D4E',
    }));

    const TransparentAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
        backgroundColor: '#B14D4E',
    }));

    const TransparentAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
        backgroundColor: '#B14D4E',
    }));


    return (
        <div className='container mx-auto'>


            <div className='px-10'>
                <div className='pt-10'>
                    <div>
                        <TransparentAccordion>
                            <TransparentAccordionSummary
                                expandIcon={<ExpandMoreIcon className='text-[#ffffcc]' />}

                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <div className="font-bold text-[#ffffcc]">“Such a good job that we no longer needed her”</div>
                            </TransparentAccordionSummary>
                            <TransparentAccordionDetails>
                                <div className="lg:flex">
                                    <div className="lg:w-2/3">
                                        <div className="lg:float-left">
                                            <div className="text-[#ffffcc]">
                                                <div className='mb-10 italic'>- John B. Hallandale Beach</div>
                                                <div>
                                                    My two children, 5th grade and 7th grade, started with Lori early this year. We had changed schools and they were both well behind their classmates. They had each failed a couple of tests. Within two months, there were getting A's! She did such a good job that my children no longer needed her help.
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="lg:w-1/3">
                                        <div className="lg:float-right lg:ml-5 mt-5">
                                            <img
                                                src={Awesome}
                                                alt=""
                                                title=""
                                                className="rounded-xl shadow shadow-[#ffffcc] bg-[#ffffcc] mx-auto"
                                            />
                                        </div>
                                    </div>



                                </div>
                            </TransparentAccordionDetails>
                        </TransparentAccordion>
                    </div>
                    <div className='mt-5'>
                        <TransparentAccordion>
                            <TransparentAccordionSummary
                                expandIcon={<ExpandMoreIcon className='text-[#ffffcc]' />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <div className="font-bold text-[#ffffcc]">“Two Lessons was all it took”</div>
                            </TransparentAccordionSummary>
                            <TransparentAccordionDetails>
                                <div className="lg:flex">
                                    <div className="lg:w-2/3">
                                        <div className="lg:float-left">
                                            <div className="text-[#ffffcc]">
                                                <div className='mb-10 italic'>-Michelle B. from N. Miami Beach, Fl</div>
                                                <div>
                                                    After two sessions with Lori my son understood how to do the word problems and his grade on the retake-test went from an F to a B+. In just two lessons!!!                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="lg:w-1/3">
                                        <div className="lg:float-right lg:ml-5 mt-5">
                                            <img
                                                src={Study}
                                                alt=""
                                                title=""
                                                className="rounded-xl shadow shadow-[#ffffcc] bg-[#ffffcc] mx-auto"
                                            />
                                        </div>
                                    </div>



                                </div>
                            </TransparentAccordionDetails>
                        </TransparentAccordion>
                    </div>


                    <div className='mt-5'>
                        <TransparentAccordion>
                            <TransparentAccordionSummary
                                expandIcon={<ExpandMoreIcon className='text-[#ffffcc]' />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <div className="font-bold text-[#ffffcc]">“A's or B's on every test”</div>
                            </TransparentAccordionSummary>
                            <TransparentAccordionDetails>
                                <div className="lg:flex">
                                    <div className="lg:w-2/3">
                                        <div className="lg:float-left">
                                            <div className="text-[#ffffcc]">
                                                <div className='mb-10 italic'>- Sandra B. Aventura</div>
                                                <div>
                                                    My daughter's Algebra teacher is completely ineffective and she was failing. She has always been a great math student. Now, she works with Lori for an hour or two before every test, and she always receives an A or B. Best of all, she's got her confidence back!
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="lg:w-1/3">
                                        <div className="lg:float-right lg:ml-5 mt-5">
                                            <img
                                                src={Algebra}
                                                alt=""
                                                title=""
                                                className="rounded-xl shadow shadow-[#ffffcc] bg-[#ffffcc] mx-auto"
                                            />
                                        </div>
                                    </div>



                                </div>
                            </TransparentAccordionDetails>
                        </TransparentAccordion>
                    </div>
                </div>
            </div>

        </div>
    )
}
