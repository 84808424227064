import React, { useState } from 'react'
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Experienced from './Component/Experienced';
import IlikeMathTutor from './Images/IlikeMathTutor.jpg'

const theme = createTheme({
    palette: {
        primary: {
            main: "#615454", // custom primary color
        },
        secondary: {
            main: "#615454", // custom secondary color
        },
    },
});

const theme2 = createTheme({
    palette: {
        primary: {
            main: "#ffffff", // custom primary color
        },
        secondary: {
            main: "#615454", // custom secondary color
        },
    },
});

export default function Contact() {

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    return (
        <div className='container mx-auto'>
            <div className='px-10 pt-10'>
                <div>
                    <Experienced />
                </div>
                <div className='text-center Edu text-2xl pb-5'>
                    <div>Contact me for more information:</div>

                    <div>
                        Florida Certified Teacher Mathematics 5th – 12th grades
                        University of Pennsylvania Graduate
                    </div>
                </div>
                <div className='lg:flex lg:gap-5'>


                    <div className='lg:w-1/2'>


                        <div><img src={IlikeMathTutor} alt='' title='' className='mx-auto rounded-xl shadow shadow-[#615454]' />
                        </div>

                    </div>
                    <div className='lg:w-1/2'>
                        <form autoComplete="off">
                            <div className='bg-[#B14D4E]  rounded-xl p-5'>
                                <form autoComplete="off">
                                    <ThemeProvider theme={theme2}>
                                        <FormControl fullWidth sx={{ m: 1 }} variant="standard" label="Name" name="name" required value={name}
                                            onChange={(event) => setName(event.target.value)}
                                            type="text">
                                            <InputLabel htmlFor="standard-adornment-amount" style={{ color: '#ffffcc' }}>Name:</InputLabel>
                                            <Input style={{ color: '#ffffff', outline: '#ffffff' }}
                                                id="standard-adornment-amount"

                                                startAdornment={<InputAdornment position="start" ><PersonIcon style={{ color: '#ffffcc' }} /></InputAdornment>}
                                            />
                                        </FormControl>
                                    </ThemeProvider>
                                </form>

                                <div>
                                    <ThemeProvider theme={theme2}>
                                        <FormControl fullWidth sx={{ m: 1 }} variant="standard"
                                            label="Phone"
                                            name="phone"
                                            required
                                            value={phone}
                                            onChange={(event) => setPhone(event.target.value)}
                                            type="tel">
                                            <InputLabel htmlFor="standard-adornment-amount" style={{ color: '#ffffcc' }}>Phone:</InputLabel>
                                            <Input style={{ color: '#ffffff' }}
                                                id="standard-adornment-amount"
                                                startAdornment={<InputAdornment position="start"><PhoneIcon style={{ color: '#ffffcc' }} /></InputAdornment>}
                                            />
                                        </FormControl>
                                    </ThemeProvider>
                                </div>


                                <div>
                                    <ThemeProvider theme={theme2}>
                                        <FormControl fullWidth sx={{ m: 1 }} variant="standard"
                                            label="Email"
                                            name="email"
                                            required
                                            value={email}
                                            onChange={(event) => setEmail(event.target.value)}
                                            type="email">
                                            <InputLabel htmlFor="standard-adornment-amount" style={{ color: '#ffffcc' }}>E-mail:</InputLabel>
                                            <Input style={{ color: '#ffffff' }}
                                                id="standard-adornment-amount"
                                                startAdornment={<InputAdornment position="start"><EmailIcon style={{ color: '#ffffcc' }} /></InputAdornment>}
                                            />
                                        </FormControl>
                                    </ThemeProvider>
                                </div>


                                <div>
                                    <ThemeProvider theme={theme2}>
                                        <FormControl fullWidth sx={{ m: 1 }} variant="standard"
                                            label="Message"
                                            name="message"
                                            required
                                            value={message}
                                            onChange={(event) => setMessage(event.target.value)}
                                        >
                                            <InputLabel htmlFor="standard-adornment-amount" style={{ color: '#ffffcc' }} sx={{ top: '-12px', paddingLeft: '5px', paddingRight: '5px' }}>Message:</InputLabel>
                                            <Input multiline rows={5} style={{ color: '#ffffff' }}
                                                id="standard-adornment-amount"
                                                startAdornment={<InputAdornment position="start" sx={{ position: 'absolute', top: 0 }}><MessageIcon style={{ color: '#ffffcc' }} /></InputAdornment>}
                                            />
                                        </FormControl>
                                    </ThemeProvider>
                                </div>

                            </div>




                            <div className='mt-5'>
                                <ThemeProvider theme={theme}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        type="submit"
                                        color="secondary"
                                        disabled
                                    >
                                        <SendIcon />
                                        &nbsp;&nbsp;
                                        <b>Submit</b>
                                    </Button>
                                </ThemeProvider>
                            </div>

                        </form>
                    </div>


                </div>
            </div>

        </div>
    )
}
