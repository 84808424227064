import React from 'react'
import ElementaryImage from './Images/Elementary.jpg'

export default function Elementary() {
    return (
        <div className='container mx-auto'>
            <div className='px-10'>
                <div className='lg:float-right lg:ml-10 lg:pt-5 mb-5'>
                    <img src={ElementaryImage} alt='' title='' className='mx-auto rounded-xl shadow shadow-[#615454]' />
                </div>
                <div className='py-5'>This is the foundation.</div>
                <div className='pb-5'>When a child struggles in their early years, it doesn't get better.</div>
                <div className='pb-5'>I've seen so many parents wait, thinking that their child will catch on. Unfortunately, it rarely happens. The more likely outcome is that your son or daughter continues to get bad grades, gets more and more behind and starts to hate math.</div>

                <div className='pb-5'>The best thing about getting tutoring right when the problems start is that tutoring can be a short term undertaking. Often we only need 6 or 8 sessions to get back on track.</div>
            </div>
        </div>
    )
}
